import React from 'react';
import ReactMarkdown from 'react-markdown';

const TextViewer = ({ content = '' }) => {
  return (
    <div style={{
      border: '1px solid #e5e7eb',
      borderRadius: '8px',
      padding: '16px',
      backgroundColor: 'white',
      minHeight: '200px'
    }}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

export default TextViewer;
