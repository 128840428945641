import React, { useEffect, useState } from 'react';
import { Button, Card, Colors, InputGroup, Label } from '@blueprintjs/core';
import ColorChartThumbnail from '../Widgets/ColorChartThumbnail';
import { appstyles } from '../../styles/appstyles';

const LayoutEditor = ({ availableSteps, initialLayout, onSave, onDelete, onCancel }) => {
  const [name, setName] = useState(initialLayout.name);
  const [layoutSteps, setLayoutSteps] = useState(initialLayout.steps || []);
  const [numrows, setNumRows] = useState();
  const [draggedItem, setDraggedItem] = useState(null);
  const [hoveredStep, setHoveredStep] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);

  useEffect(() => {
    let _numrows = 0;
    layoutSteps.forEach(step => {
      if (step.data) {
        _numrows += step.data.length;
      }
    });
    setNumRows(_numrows);
  }, [layoutSteps]);

  const handleSave = () => {
    onSave({
      ...initialLayout,
      name,
      numrows,
      steps: layoutSteps
    });
  };

  const handleDragStart = (e, item, fromLayout = false) => {
    e.dataTransfer.setData('text/plain', JSON.stringify({ ...item, fromLayout }));
    setDraggedItem(item);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    setDragOverIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    setDragOverIndex(null);

    try {
      const droppedData = JSON.parse(e.dataTransfer.getData('text/plain'));
      const { fromLayout, ...item } = droppedData;

      let newLayoutSteps = [...layoutSteps];

      if (fromLayout) {
        const dragIndex = layoutSteps.findIndex(step => step.id === item.id);
        newLayoutSteps.splice(dragIndex, 1);
        newLayoutSteps.splice(dropIndex, 0, item);
      } else {
        newLayoutSteps.splice(dropIndex, 0, { ...item });
      }

      setLayoutSteps(newLayoutSteps);
    } catch (error) {
      console.error('Error handling drop:', error);
    }
  };

  const handleTouchStart = (e, item, fromLayout = false) => {
    //e.preventDefault();
    setDraggedItem({ ...item, fromLayout });
  };

  const handleTouchMove = (e) => {
    //e.preventDefault();
  };

  const handleTouchEnd = (e, dropIndex) => {
    if (!draggedItem) return;

    if (typeof dropIndex === 'number') {
      let newLayoutSteps = [...layoutSteps];

      if (draggedItem.fromLayout) {
        const dragIndex = layoutSteps.findIndex(step => step.id === draggedItem.id);
        newLayoutSteps.splice(dragIndex, 1);
        newLayoutSteps.splice(dropIndex, 0, draggedItem);
      } else {
        newLayoutSteps.splice(dropIndex, 0, { ...draggedItem });
      }

      setLayoutSteps(newLayoutSteps);
    }

    setDraggedItem(null);
  };

  const removePart = (step, index) => {
    let newLayoutSteps = [...layoutSteps];
    newLayoutSteps.splice(index, 1);
    setLayoutSteps(newLayoutSteps);
  }

  const movePartUp = (step, index) => {
    if (index > 0) {
      let newLayoutSteps = [...layoutSteps];
      newLayoutSteps.splice(index, 1);
      newLayoutSteps.splice(index - 1, 0, step);
      setLayoutSteps(newLayoutSteps);
    }
  }

  const movePartDown = (step, index) => {
    if (index < layoutSteps.length - 1) {
      let newLayoutSteps = [...layoutSteps];
      newLayoutSteps.splice(index, 1);
      newLayoutSteps.splice(index + 1, 0, step);
      setLayoutSteps(newLayoutSteps);
    }
  }

  const addPart = (step) => {
    let newLayoutSteps = [...layoutSteps];
    newLayoutSteps.push({ ...step });
    setLayoutSteps(newLayoutSteps);
  }

  return (
    <div>
      <Card>
        <div style={appstyles.titleWithButtons}>
          <Label>Name
            <InputGroup type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </Label>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }} >
            <Button icon='trash' minimal onClick={onDelete} />
            <Button text="Save" onClick={() => handleSave()} />
            <Button text="Cancel" onClick={onCancel} />
          </div>
        </div>
        <div>Rows: {numrows} &nbsp; Motifs: {layoutSteps.length}</div>
      </Card>
      <br />
      <div style={styles.container}>
        <div style={{ ...styles.panel, ...styles.availableStepsPanel }}>
          <h2 style={styles.heading}>Available Motifs</h2>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            {availableSteps.map(step => (
              <div key={step.id} draggable="true"
                onDragStart={(e) => handleDragStart(e, step)}
                onDragEnd={handleDragEnd}
                onTouchStart={(e) => handleTouchStart(e, step)}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseEnter={() => setHoveredStep(step.id)}
                onMouseLeave={() => setHoveredStep(null)}
                style={{
                  ...styles.availableStep,
                  ...(hoveredStep === step.id ? styles.stepHover : {}),
                  ...(draggedItem?.id === step.id ? styles.dragging : {})
                }}
              >
                <div>
                  {step.name}
                  <ColorChartThumbnail data={step.data} mincols={24}
                  />
                </div>
                <Button icon="plus" minimal onClick={() => addPart(step)} />
              </div>
            ))}
          </div>
        </div>

        <div style={{ ...styles.panel, ...styles.layoutPanel }}>
          <h2 style={styles.heading}>{name}</h2>
          <div>
            {layoutSteps.map((step, index) => (
              <div key={`${step.id}-${index}`}>
                <div style={{ ...styles.dropZone, ...(dragOverIndex === index ? styles.dragOver : {}) }}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, index)}
                  onTouchEnd={(e) => handleTouchEnd(e, index)}
                />

                <div draggable="true"
                  onDragStart={(e) => handleDragStart(e, step, true)}
                  onDragEnd={handleDragEnd}
                  onTouchStart={(e) => handleTouchStart(e, step, true)}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  onMouseEnter={() => setHoveredStep(step.id)}
                  onMouseLeave={() => setHoveredStep(null)}
                  style={{
                    ...styles.step,
                    ...(hoveredStep === step.id ? styles.stepHover : {}),
                    ...(draggedItem?.id === step.id ? styles.dragging : {})
                  }}
                >
                  <ColorChartThumbnail data={step.data} mincols={24} size={2} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button icon="arrow-up" minimal small onClick={() => movePartUp(step, index)} />
                    <Button icon="cross" minimal small onClick={() => removePart(step, index)} />
                    <Button icon="arrow-down" minimal small onClick={() => movePartDown(step, index)} />
                  </div>
                </div>
              </div>
            ))}

            <div style={{
              ...styles.finalDropZone,
              ...(dragOverIndex === layoutSteps.length ? styles.dragOver : {})
            }}
              onDragOver={(e) => handleDragOver(e, layoutSteps.length)}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, layoutSteps.length)}
              onTouchEnd={(e) => handleTouchEnd(e, layoutSteps.length)}
            >
              Drop here to add motif
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    gap: 8,
  },
  panel: {
    backgroundColor: '#f3f4f6',
    padding: 8,
    borderRadius: '0.375rem'
  },
  availableStepsPanel: {
    width: 180
  },
  layoutPanel: {
    flex: 1,
    minHeight: '24rem'
  },
  heading: {
    fontSize: '1.125rem',
    fontWeight: '600',
    marginBottom: '1rem'
  },
  stepContainer: {
    marginBottom: '0.5rem'
  },
  step: {
    //padding: '0.75rem',
    backgroundColor: 'white',
    //borderRadius: '0.375rem',
    //boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    cursor: 'move',
    transition: 'background-color 0.2s',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0
  },
  availableStep: {
    padding: 12,
    backgroundColor: 'white',
    borderRadius: '0.375rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    cursor: 'move',
    transition: 'background-color 0.2s',
    display: 'flex',
    justifyContent: 'space-between',
  },
  stepHover: {
    backgroundColor: '#f9fafb'
  },
  dragging: {
    opacity: 0.5,
    backgroundColor: '#f9fafb'
  },
  dragOver: {
    backgroundColor: Colors.GREEN5
  },
  dropZone: {
    height: 10,
    width: '100%'
  },
  finalDropZone: {
    height: '6rem',
    border: '2px dashed #d1d5db',
    borderRadius: '0.375rem',
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#9ca3af'
  }
};

export default LayoutEditor;