import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { Drawer, Button, Menu, MenuDivider, MenuItem, NavbarHeading, NavbarGroup, Navbar, Popover } from '@blueprintjs/core';
import { useGetUserQuery } from '../store/docApi';
import { appstyles } from '../styles/appstyles';

export default function NavMenu() {
  const { data: userinfo } = useGetUserQuery({}, { skip: !auth.currentUser });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  let user = auth.currentUser;

  function menuClicked(path) {
    setIsMenuOpen(false);
    setIsUserMenuOpen(false);
    navigate(path);
  }

  function logoutClicked() {
    setIsMenuOpen(false);
    setIsUserMenuOpen(false);
    signOut(auth).then(() => window.location.reload());
    navigate("/");
  }

  function isActiveMenu(name) {
    if (name === "/designs") {
      return location.pathname.startsWith(name) ||
        location.pathname.startsWith("/pattern") ||
        location.pathname.startsWith("/motif") ||
        location.pathname.startsWith("/collection");
    } else if (name === "/projects") {
      return location.pathname.startsWith('/project');
    } else {
      return location.pathname.startsWith(name);
    }
  }

  const userMenu = user && (
    <Menu>
      <MenuItem icon="person" text="Profile" onClick={() => menuClicked('/profile')} />
      <MenuItem icon="th-list" text="Orders" onClick={() => menuClicked("/orders")} />
      <MenuItem icon="log-out" text="Logout" onClick={() => logoutClicked()} />
    </Menu>
  );

  const designMenu = user && (
    <Menu>
      <MenuDivider title="Designs" />
      <MenuItem text="Patterns" onClick={() => menuClicked("/patterns")} />
      <MenuItem text="Motifs" onClick={() => menuClicked("/motifs")} />
      <MenuItem text="Collections" onClick={() => menuClicked("/collections")} />
    </Menu>
  );

  const menu = user && (
    <Menu>
      <MenuItem icon="home" text="Home" onClick={() => menuClicked("/")} />
      <MenuDivider />
      <MenuItem icon="grid-view" text="Projects" onClick={() => menuClicked("/projects")} />
      <MenuItem icon="manual" text="Library" onClick={() => menuClicked("/patterns")} />
      <MenuItem icon="annotation" text="Notes" onClick={() => menuClicked("/notes")} />
      <MenuDivider />
      <MenuItem icon="layout-grid" text="Patterns" onClick={() => menuClicked("/patterns")} />
      <MenuItem icon="shapes" text="Motifs" onClick={() => menuClicked("/motifs")} />
      <MenuItem icon="box" text="Collections" onClick={() => menuClicked("/collections")} />
      <MenuDivider />
      <MenuItem icon="search" text="Explore" onClick={() => menuClicked("/shops")} />
      <MenuItem icon="calendar" text="Events" onClick={() => menuClicked("/events")} />
      <MenuItem icon="document" text="Notes" onClick={() => menuClicked("/notes")} />
      <MenuItem icon="book" text="References" onClick={() => menuClicked("/references")} />
    </Menu>
  );

  /*
      <MenuDivider />
      {userinfo && userinfo.name &&
        <MenuItem icon="user" text={`@${userinfo.name} Projects`} onClick={() => menuClicked(`/@${userinfo.name}`)} />
      }
      <MenuDivider />
      <MenuItem icon="search" text="Search" onClick={() => menuClicked("/search")} />
      <MenuItem icon="layout-grid" text="Items" onClick={() => menuClicked("/item")} />
      <MenuItem icon="graph" text="Nodes" onClick={() => menuClicked("/node")} />
      <MenuDivider />
      <MenuItem icon="cube" text="New project" onClick={() => menuClicked(`@${userinfo.name}/create`)}
        selected={location.pathname.startsWith("/project/create")} />
      <MenuItem icon="snowflake" text="New motif" onClick={() => menuClicked("/motif/create")}
        selected={location.pathname.startsWith("/motif/create")} />
      <MenuItem icon="book" text="New collection" onClick={() => menuClicked("/collection/create")}
        selected={location.pathname.startsWith("/collection/create")} />
  */

  return <Navbar style={{ minWidth: 340 }}>
    <NavbarGroup>
      {user && <>
        <Button minimal icon="menu" onClick={() => setIsMenuOpen(true)} />
        <Drawer position="left" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}
          canOutsideClickClose canEscapeKeyClose size="200px" title="Options">
          {menu}
        </Drawer>
        <Button minimal text="Explore" onClick={() => menuClicked("/explore")}
          intent={isActiveMenu("/explore") ? 'primary' : 'none'} />
        <Button minimal text="Projects" onClick={() => menuClicked("/projects")}
          intent={isActiveMenu("/projects") ? 'primary' : 'none'} />
        <Button minimal text="Library" onClick={() => menuClicked("/library")}
          intent={isActiveMenu("/library") ? 'primary' : 'none'} />
        <Popover content={designMenu} position="bottom">
          <Button minimal icon="more"
            intent={isActiveMenu("/designs") ? 'primary' : 'none'} />
        </Popover>
      </>}
    </NavbarGroup>
    {!user && <NavbarGroup>
      <NavbarHeading style={appstyles.title} onClick={() => navigate("/")}>
        &nbsp;&nbsp;&nbsp;Stitch Portal</NavbarHeading>
    </NavbarGroup>}
    {user && userinfo && <NavbarGroup align='right'>
      {user && userinfo && userinfo.picture ?
        <img src={userinfo.picture} alt="avatar" style={styles.usernameCircleStyle}
          onClick={() => setIsUserMenuOpen(true)} /> :
        <div style={styles.usernameCircleStyle} onClick={() => setIsUserMenuOpen(true)}>
          {userinfo.name.slice(0, 2).toUpperCase()} </div>}
      <Drawer position="right" isOpen={isUserMenuOpen} onClose={() => setIsUserMenuOpen(false)}
        canOutsideClickClose canEscapeKeyClose size="200px" title={userinfo.name}>
        {userMenu}
      </Drawer>
    </NavbarGroup>}
    {!user && <NavbarGroup align='right'>
      <Button minimal onClick={() => menuClicked("/login")}>Login</Button>
    </NavbarGroup>}
  </Navbar>
}

const styles = {
  usernameCircleStyle: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    borderWidth: '1.5px',
    borderStyle: 'solid',
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    fontSize: '18px',
    color: 'grey',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  menuicon: {
    cursor: 'pointer',
    color: 'grey',
    width: '40px',
    height: '40px'
  }
}