import { Colors } from "@blueprintjs/core";

export default function ProjectTOC({ steps, currentStep, onStepPress, isOpen }) {

    const renderSteps = (steps, level = 0) => {
        return steps && steps.map((step, index) => (
            <div key={index} style={{ marginLeft: level * 10 }}>
                {level === 0 &&
                    <div style={ currentStep === index ? styles.activeStep : styles.step }
                        onClick={() => onStepPress(index)}>
                        <>
                            {step.title && <div style={styles.steptitle}>{step.title}</div>}
                            {step.name && <div><i>{step.name}</i></div>}
                        </>
                    </div>}
                {false && <>
                    {step.title && <div style={styles.substeptitle}>{step.title}</div>}
                    {step.name && <div><i>{step.name}</i></div>}
                </>}
                {currentStep === index && step.steps && renderSteps(step.steps, level + 1)}
            </div>
        ));
    };

    return isOpen && <div style={styles.main}>{renderSteps(steps)}</div>
};

const styles = {
    main: {
        maxWidth: 140,
        minWidth: 140,
        background: 'white',
        marginRight: 16,
        borderRight: '1px solid #ddd',
    },
    step: {
        padding: 4,
        cursor: 'pointer',
    },
    activeStep: {
        padding: 4,
        cursor: 'pointer',
        backgroundColor: Colors.LIGHT_GRAY3
    },
    steptitle: {
        fontWeight: 600 // bold
    },
    substeptitle: {
        fontWeight: 550 // semi-bold
    }
};

