const getWordCount = (text) => {
    if (!text) return 0;
  
    // Remove markdown formatting
    const cleanText = text
      // Remove code blocks
      .replace(/```[\s\S]*?```/g, '')
      // Remove inline code
      .replace(/`[^`]*`/g, '')
      // Remove URLs
      .replace(/\[([^\]]*)\]\([^)]*\)/g, '$1')
      // Remove markdown headers
      .replace(/#{1,6}\s/g, '')
      // Remove bold/italic
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      .replace(/(\*|_)(.*?)\1/g, '$2')
      // Remove images
      .replace(/!\[([^\]]*)\]\([^)]*\)/g, '$1')
      // Remove HTML tags
      .replace(/<[^>]*>/g, '');
  
    // Split by whitespace and filter out empty strings
    const words = cleanText
      .trim()
      .split(/\s+/)
      .filter(word => 
        word.length > 0 && 
        // Filter out strings that are just punctuation
        !/^[.,/#!$%^&*;:{}=\-_`~()]+$/.test(word)
      );
  
    return words.length;
  };
  
  // For more detailed analysis
  const getTextStatistics = (text) => {
    if (!text) {
      return {
        wordCount: 0,
        characterCount: 0,
        characterNoSpaces: 0,
        sentenceCount: 0,
        paragraphCount: 0,
      };
    }
  
    const cleanText = text.replace(/```[\s\S]*?```/g, ''); // Remove code blocks
    
    return {
      wordCount: getWordCount(text),
      characterCount: cleanText.length,
      characterNoSpaces: cleanText.replace(/\s/g, '').length,
      sentenceCount: cleanText
        .split(/[.!?]+/)
        .filter(sentence => sentence.trim().length > 0).length,
      paragraphCount: cleanText
        .split(/\n\s*\n/)
        .filter(para => para.trim().length > 0).length,
    };
  };
  
  export { getWordCount, getTextStatistics };
  
  // Example usage:
  // const text = `
  // # Heading
  // 
  // This is a **bold** paragraph with some *italic* text.
  // It has multiple sentences! And numbers like 123.
  // 
  // \`\`\`
  // const code = true;
  // \`\`\`
  // 
  // - List item 1
  // - List item 2
  // `;
  // 
  // console.log(getWordCount(text)); // Word count only
  // console.log(getTextStatistics(text)); // Detailed statistics
  