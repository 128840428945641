import { Colors } from "@blueprintjs/core";

export const appstyles = {
    content: {
        minWidth: 340,
        maxWidth: 1200,
        paddingLeft: 16,
        margin: '0 auto',

    },
    title: {
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontSize: 22
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        fontSize: 9,
        backgroundColor: '#f6f7f9',
        padding: 4
    },
    tileGrid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 8,
    },
    tile: {
        width: 136,
        margin: 8,
        padding: 8
    },
    tileImg: {
        height: 120,
        width: 120,
        objectFit: 'cover',
        borderRadius: 2
    },
    tileNoimg: {
        padding: 16,
        backgroundColor: 'white',
        color: 'lightgrey',
        borderWidth: 1,
        borderStyle: 'solid',
        height: 120,
        width: 120,
        borderRadius: 2,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    tileLink: {
        fontSize: 'small',
        color: 'grey',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        textAlign: 'center',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical'
    },
    tileTitle: {
        marginTop: 8,
        marginBottom: 8,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        textAlign: 'center',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    card: {
        margin: 8,
        display: 'flex',
    },
    cardImg: {
        height: 92,
        width: 92,
        objectFit: 'cover',
        borderRadius: 2
    },
    cardNoimg: {
        padding: 16,
        backgroundColor: 'white',
        color: 'lightgrey',
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        height: 92,
        width: 92,
        borderRadius: 2,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    cardTitle: {
        paddingTop: 4,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    cardContent: {
        paddingLeft: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
    },
    cardDescription: {
        fontSize: 'small',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    spinner: { paddingTop: 100, height: 200 },
    stepid: { fontSize: 'x-small', color: 'lightgrey', fontStyle: 'italic', textAlign: 'right', float: 'right' },
    id: { fontSize: 'x-small', color: 'grey', marginTop: 4 },
    titleWithButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        borderStyle: 'solid',
        borderWidth: "0 0 1px 0",
        borderColor: 'lightgrey',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: "100%"
    },
    tabButtonGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
    },
    tabButton: {
        color: Colors.GRAY1,
    },
    activeTabButton: {
        borderWidth: "0 0 2px 0",
        borderStyle: 'solid',
        borderRadius: 0,
    },
};