import { Spinner } from "@blueprintjs/core";
import { useGetItemsQuery } from "../../store/docApi";
import { appstyles } from "../../styles/appstyles";
import { Link } from "react-router-dom";

export default function References() {
    const {data: items, isLoading} = useGetItemsQuery({ label: 'reference' });

    
    return isLoading ? <Spinner /> : (
        <div style={appstyles.content}>
            <h1>References</h1>
            {items.map((item) => (
                <div key={item.id}>
                    <Link to={`/reference/${item.pk}/${item.id}`}>{item.name}</Link>
                </div>
            ))}
        </div>
    );
}