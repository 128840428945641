import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Callout, Colors, Spinner, Tooltip } from '@blueprintjs/core';
import { useGetItemQuery } from '../../store/docApi';
import { usePackagePatternMutation } from '../../store/appApi';
import Instructions from './Instructions';
import Overview from './Overview';
import ProductCreate from '../Shop/ProductCreate';
import { appstyles } from '../../styles/appstyles';
import ItemMetadata from '../Widgets/ItemMetadata';

export default function Pattern() {
  let params = useParams();
  const patternPk = params.pk;
  const patternId = params.id;

  // TODO: do we need projectId and stepId?
  const projectId = params.projectId;
  const stepId = params.stepId;

  const { data: item, isLoading } = useGetItemQuery({ label: "pattern", pk: params.pk, id: params.id });
  const [packagePattern, result] = usePackagePatternMutation();

  const [showPublish, setShowPublish] = useState(false);
  const [selectedTab, setSelectedTab] = useState(params.projectId ? "instructions" : "overview");
  const [currentLang, setCurrentLang] = useState('en');
  const [statusMessage, setStatusMessage] = useState("");
  const navigate = useNavigate();

  function tabStyle(selected) {
    return selected ? {
      borderColor: Colors.TURQUOISE3,
      //color: 'white',
      borderWidth: "0 0 4px 0", borderStyle: 'solid',
    } : {}
  }

  function edit() {
    navigate(`/item/pattern/${item.pk}/${item.id}`)
  }

  function publish() {
    setShowPublish(true);
  }

  async function callPackagePattern() {
    await packagePattern({ label: "pattern", pk: patternPk, id: patternId })
  }

  useEffect(() => {
    if (result.isSuccess) {
      setStatusMessage("Published.");
    }
  }, [result]);

  return isLoading ? <Spinner style={appstyles.spinner} /> :
    item && <div style={appstyles.content}>
      <div>
        <div>
          <h2>{item.name}</h2>
          {/*node && <i> ({node.name} {node.stripeProductId && node.stripeProductId})</i>*/}
          {item.author && <p>by <Link to={`/@${item.author}`}>@{item.author}</Link> </p>}
        </div>
        <div>
          {item.lang && item.lang.map((lang, i) =>
            <Button small key={i} style={tabStyle(lang === currentLang)}
              onClick={() => setCurrentLang(lang)}>{lang}</Button>
          )}
        </div>
      </div>
      <div style={appstyles.tabContainer}>
        <div style={appstyles.tabButtonGroup}>
          <Button minimal style={selectedTab === "overview" ? appstyles.activeTabButton : appstyles.tabButton}
            onClick={() => setSelectedTab("overview")}>Overview</Button>
          <Button minimal style={selectedTab === "instructions" ? appstyles.activeTabButton : appstyles.tabButton}
            onClick={() => setSelectedTab("instructions")}>Instructions</Button>
        </div>
        <div style={appstyles.tabButtonGroup}>
          <Button minimal icon="edit" onClick={() => edit()} disabled />
          <Tooltip content="Publish" placement="bottom">
            <Button minimal icon="cloud-upload" onClick={() => callPackagePattern()} />
          </Tooltip>
          <Tooltip content="Sell" placement="bottom">
            <Button minimal icon="shop" onClick={() => publish()} />
          </Tooltip>
        </div>
      </div>
      <br />
      {statusMessage && <Callout intent="success" style={{ marginTop: 16 }}>{statusMessage}</Callout>}
      {showPublish && <ProductCreate item={item} />}
      <br />
      {selectedTab === "overview" && <Overview patternId={patternId} patternPk={patternPk} lang={currentLang} />}
      {selectedTab === "instructions" && <Instructions patternId={patternId} patternPk={patternPk} projectId={projectId} stepId={stepId} lang={currentLang} />}
      <ItemMetadata item={item} />
    </div>
}
