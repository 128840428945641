import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, InputGroup, Tag, HTMLSelect } from "@blueprintjs/core";
import { useGetItemsQuery } from '../../store/docApi';
import { isArrayOfStrings } from '../../utils';
import { appstyles } from '../../styles/appstyles';

const ExplorePage = () => {
  const navigate = useNavigate();
  const { data: shops } = useGetItemsQuery({ "label": "shop" });
  const [filteredShops, setFilteredShops] = useState([]);
  const [favoriteShops, setFavoriteShops] = useState([]);
  const [savedFavorites, setSavedFavorites] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTag, setSelectedTag] = useState("All");

  const tags = ["All", "Knitting", "Crochet", "Motifs"];

  useEffect(() => {
    if (shops) {
      const _filteredShops = shops.filter(shop => {
        const matchesSearch = (shop.name && shop.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (shop.owner && shop.owner.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (shop.tags && shop.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase())));
        const matchesTags = selectedTag === "All" || (shop.tags && shop.tags.includes(selectedTag));
        return matchesSearch && matchesTags;
      });
      setFilteredShops(_filteredShops);

      if (savedFavorites) {
        const _favoriteShops = shops.filter(shop => {
          return savedFavorites.includes(shop.id);
        });
        setFavoriteShops(_favoriteShops);
      }
    }
  }, [shops, searchQuery, selectedTag, savedFavorites]);

  useEffect(() => {
    try {
      const _savedFavs = JSON.parse(localStorage.getItem('favoriteShops'));
      setSavedFavorites(isArrayOfStrings(_savedFavs) ? _savedFavs : []);
    } catch (error) { console.log("Error loading saved favorites!") }
  }, []);

  useEffect(() => {
    localStorage.setItem('favoriteShops', JSON.stringify(savedFavorites));
  }, [savedFavorites]);

  const toggleFavorite = (shopId) => {
    setSavedFavorites(prev => {
      if (prev.includes(shopId)) {
        return prev.filter(id => id !== shopId);
      } else {
        return [...prev, shopId];
      }
    });
  };

  const ShopCard = ({ shop, isFavorite, onFavoriteToggle }) => {
    return (
      <Card onClick={() => navigate(`/explore/${shop.pk}`)} interactive >
        <div style={{ position: 'relative' }}>
          {shop.picture ? <img src={shop.picture} alt={shop.name} style={{ width: '100%', height: '100px', objectFit: 'contain' }} /> :
           <div style={styles.shopName}>{shop.name}</div>}
          {false && (
            <Button icon="heart" minimal intent={isFavorite ? "danger" : "none"}
              onClick={(e) => { e.stopPropagation(); onFavoriteToggle(); }}
              style={{ position: 'absolute', top: '-10px', right: '-10px', background: 'rgba(237, 231, 225, 0.9)', borderRadius: '50%', color: isFavorite ? undefined : '#5C7080' }} />
          )}
        </div>

        {false && <div style={{ display: 'flex', gap: '8px', marginBottom: '16px', flexWrap: 'wrap' }}>
          {shop.tags && shop.tags.map(tag => (
            <Tag key={tag} minimal>{tag}</Tag>
          ))}
        </div>}
      </Card>
    );
  };

  return (
    <div style={appstyles.content}>
      <h1>Explore</h1>
      <br />
      <div style={styles.controls}>
        <InputGroup leftIcon="search" placeholder="Search ..."
          value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ minWidth: '200px' }} />
        <HTMLSelect value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)} options={tags} style={{ width: '150px' }} />
      </div>

      {false && favoriteShops && favoriteShops.length > 0 &&
        <div>
          <h2>Favorites</h2>
          <div style={styles.grid}>
            {favoriteShops.map(shop => (
              <ShopCard key={shop.id} shop={shop} isFavorite={savedFavorites.includes(shop.id)} onFavoriteToggle={() => toggleFavorite(shop.id)} />
            ))}
          </div>
        </div>
      }

      <div>
        <br/><br/>
        <div style={styles.grid}>
          {filteredShops.map(shop => (
            <ShopCard key={shop.id} shop={shop} isFavorite={savedFavorites.includes(shop.id)} onFavoriteToggle={() => toggleFavorite(shop.id)} />
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  controls: { display: 'flex', gap: '16px', flexWrap: 'wrap', alignItems: 'center' },
  grid: { display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))', gap: '16px' },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  shopName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    fontSize: '1.5em'
  }
}

export default ExplorePage;
