import { useState } from "react";
import { useUpdatePropertyMutation } from '../../store/docApi';
import { Button, Card } from "@blueprintjs/core";

export default function ItemColors({ item }) {
    const defaultColors = [
        "#ffffff",
        "#d3d3d3",
        "#808080"
    ];
    const [isEditing, setIsEditing] = useState(false);
    const [colors, setColors] = useState(item && item.colors ? item.colors : defaultColors);
    const [updateProperty] = useUpdatePropertyMutation()

    const onAddColor = () => {
        const newcolors = [...colors];
        newcolors.push("#ffffff");
        setColors(newcolors);
        //onSaveColors(newcolors);
    }

    const onCancel = () => {
        setColors(item && item.colors ? item.colors : defaultColors);
        setIsEditing(false);
    }

    const onSaveColors = async () => {
        await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "colors", value: colors })
        setIsEditing(false);
    }

    return colors ? (
        <Card>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 4 }}>
                {!isEditing && colors.map((color, i) => {
                    return <div key={i} style={styles.colorProps}>
                        <div style={{ ...styles.inputColor, backgroundColor: color }}></div>
                        <div>{color}</div>
                    </div>
                })}
                {isEditing && colors.map((color, i) => {
                    return <div key={i} style={styles.colorProps}>
                        <input type="color" value={color} style={styles.inputColor}
                            onChange={(e) => setColors((prevColors) => {
                                const newColors = [...prevColors];
                                newColors[i] = e.target.value;
                                return newColors;
                            })} />
                        <div>{color}</div>
                        <Button icon="trash" minimal onClick={() => {
                            const newColors = [...colors];
                            newColors.splice(i, 1);
                            setColors(newColors);
                        }} />
                    </div>
                })}
                {isEditing && <Button icon="plus" minimal onClick={() => onAddColor()}>Add</Button>}
                {isEditing && <Button icon="floppy-disk" minimal onClick={() => onSaveColors(colors)}>Save</Button>}
                {isEditing && <Button icon="cross" minimal onClick={() => onCancel()}>Cancel</Button>}
                {!isEditing && <Button icon="edit" minimal onClick={() => setIsEditing(true)}>Edit</Button>}
            </div>
        </Card>
    ) : <span>no colors</span>
}

const styles = {
    inputColor: {
        width: 48,
        height: 32,
        backgroundColor: 'white',
        cursor: 'pointer',
        margin: 0,
        borderRadius: 4,
        borderColor: 'lightgrey',
        borderStyle: 'solid',
        borderWidth: 1
    },
    colorProps: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 64       
    }
}

