import { Colors, Tooltip } from '@blueprintjs/core';
import React from 'react';

const Stepper = ({ steps, currentStep, onStepPress }) => {
  return (
    <div style={styles.stepperContainer}>
      {steps.map((step, index) => (
        <Tooltip key={index} content={step.title ? step.title : step.name}>
        <div key={index} style={currentStep === index ? styles.activeStep : styles.step}
          onClick={() => onStepPress(index)}>
          <span>{index + 1}</span>
        </div>
        </Tooltip>
      ))}
    </div>
  );
};

const styles = {
  stepperContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    fontWeight: 600,
    width: 24,
    height: 24,
    borderRadius: 12,
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: 1,
    textAlign: 'center',
    color: Colors.DARK_GRAY5,
    borderColor: Colors.LIGHT_GRAY1,
    backgroundColor: Colors.WHITE,
  },
  activeStep: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    fontWeight: 620,
    width: 24,
    height: 24,
    borderRadius: 12,
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.LIGHT_GRAY1,
    backgroundColor: Colors.LIGHT_GRAY3,
  }
};

export default Stepper;
