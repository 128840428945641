import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, CardList, Card, ProgressBar, Spinner, Icon } from '@blueprintjs/core';
import {
    useGetItemQuery, useUpdatePropertyMutation, useGetConnectedAllQuery,
    useDeleteItemMutation, useCreateItemMutation
} from '../../store/docApi';
import { useGetProjectQuery } from "../../store/appApi";
import { useDeleteImageMutation } from '../../store/mediaApi';
import Links from "../Widgets/Links";
import FileUpload from '../Widgets/FileUpload';
import Instagram from '../Widgets/Instagram';
import { ProjectWizard } from './ProjectWizard';
import { appstyles } from '../../styles/appstyles';
import ItemMetadata from '../Widgets/ItemMetadata';
import ItemColors from '../Widgets/ItemColors';
import Questions from '../Note/Questions';
import TextViewer from '../Note/TextViewer';

const imgUrl = "https://m.stitchso.com/img/";

export default function ProjectDetails({ projectId, username, index }) {
    const navigate = useNavigate();
    const { data: item, isLoading, isError, error } = useGetProjectQuery({ pk: username, id: projectId });
    const { data: notes, isLoading: notesLoading } = useGetConnectedAllQuery({
        _id: `project/${username}/${projectId}`, container: "note", pk: username
    });
    const [referenceId, setReferenceId] = useState();
    const { data: reference } = useGetItemQuery({ _id: referenceId }, { skip: referenceId === undefined });
    const [updateProperty, { isLoading: isUpdating }] = useUpdatePropertyMutation();
    const [createItem] = useCreateItemMutation();
    const [deleteItem] = useDeleteItemMutation();
    const [deleteImage] = useDeleteImageMutation();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteProjectConfirmOpen, setDeleteProjectConfirmOpen] = useState(false);
    const [deleteUrl, setDeleteUrl] = useState();
    const [showAddPicture, setShowAddPicture] = useState(false);
    const [pattern, setPattern] = useState();
    const [connectionIndex, setConnectionIndex] = useState(0);
    const [view, setView] = useState('instructions');

    if (item) document.title = item.name;

    useEffect(() => {
        if (item) {
            let _index = index && index < item.connections.length ? index : 0;
            setConnectionIndex(_index);
            item.connections && item.connections.length > 0 && setPattern(item.connections[_index].pattern);
            // find a connection with a _type of 'reference and retrieve it
            const reference = item.connections ? item.connections.find(c => c._type === 'reference') : null;
            if (reference) {
                setReferenceId(reference._id);
            }
        }
    }, [item, index]);

    function edit() {
        navigate(`/item/project/${item.pk}/${item.id}`)
    }

    function deleteProject() {
        deleteItem({ label: item.label, pk: item.pk, id: item.id });
        navigate("/projects");
    }

    function addPicture() {
        setShowAddPicture(true);
    }

    function deletePictureConfirm(url) {
        setDeleteUrl(url);
        setDeleteConfirmOpen(true);
    }

    function deletePicture() {
        setDeleteConfirmOpen(false);
        if (deleteUrl.startsWith(imgUrl)) {
            deleteImage({ name: deleteUrl.substr(imgUrl.length) })
        }
        let newpictures = item.pictures.filter((el) => { return el !== deleteUrl });
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "pictures", value: newpictures })
    }

    function setPicture(picture) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "picture", value: picture })
    }

    function updateSummary(summary) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "summary", value: summary })
    }

    function getPictureName(picture) {
        let tok = picture.split("/");
        return tok[tok.length - 1];

    }

    const ProjectDetails = () => {
        return <div>
            {item.description && <p>{item.description}</p>}
            <div style={{ marginTop: 16, marginBottom: 16 }}>
                <Button onClick={() => addPicture()} >Add picture</Button>
                &nbsp;&nbsp;
                <Button icon='edit' onClick={() => edit()} ></Button>
                &nbsp;&nbsp;
                <Button icon={item.summary ? 'eye-on' : 'eye-off'}
                    onClick={() => updateSummary(!item.summary)} />
                &nbsp;&nbsp;
                <Button icon='trash' onClick={() => setDeleteProjectConfirmOpen(true)} />
            </div>
            <ItemColors item={item} />
            {item.pictures && <div style={styles.pictures}>
                {item.pictures.map((picture, i) => (
                    <span key={i}>
                        <img style={styles.picture} src={picture} alt={picture} />
                        <br clear="all" />
                        <div style={styles.controls}>
                            {getPictureName(picture)}
                            <span>
                                <Button icon="confirm" minimal
                                    intent={picture === item.picture ? 'primary' : 'none'}
                                    onClick={() => setPicture(picture)} />
                                <Button icon="trash" minimal
                                    onClick={() => deletePictureConfirm(picture)} />
                            </span>
                        </div>
                    </span>

                ))}
            </div>}
            {item.prompt && <div><b>Prompt: </b>{item.prompt}</div>}
            {false && <Instagram imageUrl={item.picture} caption={item.caption} />}
            {showAddPicture && <div>
                <br />
                <FileUpload folder={username} item={item} />
            </div>}
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
                onConfirm={() => deletePicture()}>
                <p>Delete this picture?</p>
            </Alert>
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                isOpen={deleteProjectConfirmOpen} onCancel={() => setDeleteProjectConfirmOpen(false)}
                onConfirm={() => deleteProject()}>
                <p>Delete this project?</p>
            </Alert>
        </div>
    }

    const ProjectInstructions = () => {
        return <div>
            {<div>
                {item.connections && item.connections.map((connection, i) => (
                    <div key={i}>{connection._name}
                        <Link to={`/library/${connection._id}`}>{connection._id}</Link>
                        &nbsp;&nbsp;
                        <Link to={`/project/${item.pk}/${item.id}/${i}`}>Make it!</Link>
                    </div>
                ))}
                <br /></div>}
            <ProjectWizard item={item} stepIndex={0} connectionIndex={connectionIndex} />
        </div>
    }

    const ProjectResources = () => {
        return pattern && pattern.links && <Links links={pattern.links} vertical />
    }

    async function createNote(event) {
        event.preventDefault();
        var newNote = {
            pk: item.pk,
            label: "note",
            name: "New note",
            startDate: new Date().toISOString(),
        };
        if (item.pk && item.id) {
            newNote.connections = [{
                _id: `project/${item.pk}/${item.id}`,
                _type: item.label,
                _name: item.name
            }];
        }
        await createItem({ label: "note", data: newNote });
    }

    const ProjectNotes = ({ notes }) => {
        if (notesLoading) return <Spinner style={appstyles.spinner} />
        return notes && <div>
            <Button onClick={createNote}>Add note</Button>
            <br /><br />
            <CardList>
                {notes.map((note, i) => (
                    <Card key={i} interactive onClick={() => navigate(`/note/${note.pk}/${note.id}`)}
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                            <b>{note.name}</b><br />{note.description}<br />
                            <TextViewer content={note.note} />
                            {note.references && <div>References: {note.references.length}</div>}
                        </span>
                        <Icon icon='chevron-right' />
                    </Card>
                ))}
            </CardList>
        </div>
    }

    return isLoading ? <Spinner style={appstyles.spinner} /> :
        isError ? error && error.status === 404 && <center style={{ paddingTop: 100 }}>This project is yet to be created!</center> :
            item &&
            <div style={appstyles.content}>
                <h2>{item.name}</h2>
                {isUpdating && <div style={{ paddingTop: 20, paddingBottom: 20 }}><ProgressBar intent='danger' /></div>}
                <div style={appstyles.tabContainer}>
                    <div style={appstyles.tabButtonGroup}>
                        <Button minimal onClick={() => setView('details')}
                            style={view === 'details' ? appstyles.activeTabButton : appstyles.tabButton}>Details</Button>
                        <Button minimal onClick={() => setView('instructions')}
                            style={view === 'instructions' ? appstyles.activeTabButton : appstyles.tabButton}>Instructions</Button>
                        <Button minimal onClick={() => setView('resources')}
                            style={view === 'resources' ? appstyles.activeTabButton : appstyles.tabButton}>Resources</Button>
                        <Button minimal onClick={() => setView('questions')}
                            style={view === 'questions' ? appstyles.activeTabButton : appstyles.tabButton}>Questions</Button>
                        <Button minimal onClick={() => setView('notes')}
                            style={view === 'notes' ? appstyles.activeTabButton : appstyles.tabButton}>Notes</Button>
                    </div>
                </div>
                <br />
                {view === 'details' && <ProjectDetails />}
                {view === 'instructions' && <ProjectInstructions />}
                {view === 'resources' && <ProjectResources />}
                {view === 'questions' && <Questions item={item} stepIndex={0} connectionIndex={connectionIndex} reference={reference} />}
                {view === 'notes' && <ProjectNotes notes={notes} />}
                <ItemMetadata item={item} />
            </div>
}

const styles = {
    header: {
        display: 'flex'
    },
    topPicture: {
        marginRight: 10,
        maxWidth: 80,
        borderRadius: 5
    },
    pictures: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    picture: {
        maxHeight: 200,
        paddingRight: 10
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5
    },
    description: {
        padding: 15
    }
};

