import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Markdown } from 'tiptap-markdown';
import { marked } from 'marked';
import { getWordCount } from './TextStats';

export const markdownToHtml = (markdown) => {
    try {
        return marked(markdown || '', {
            breaks: true,      // Convert \n to <br>
            gfm: true,        // GitHub Flavored Markdown
            headerIds: true,  // Add ids to headers
            mangle: false,    // Don't escape HTML
        });
    } catch (error) {
        console.error('Error converting markdown to HTML:', error);
        return '';
    }
};


const TipTapEditor = ({ content = '', setContent }) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Markdown
        ],
        content: content,
        autofocus: true,
        editorProps: {
            attributes: {
                style: 'min-height: 200px; padding: 8px 16px;',
            },
        },
        onUpdate: ({ editor }) => {
            const markdown = editor.storage.markdown.getMarkdown();
            setContent(markdown);
        },
    });

    // Update editor content when content prop changes
    useEffect(() => {
        if (editor && content !== editor.storage.markdown.getMarkdown()) {
            editor.commands.setContent(content);
        }
    }, [content, editor]);

    const MenuButton = ({ command, active, children }) => (
        <button onMouseDown={(e) => { e.preventDefault(); command(); }}
            style={{
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: active ? '#e5e7eb' : 'transparent',
                border: 'none',
                cursor: 'pointer',
                ':hover': { backgroundColor: '#f3f4f6' }
            }}
        >
            {children}
        </button>
    );

    const Divider = () => (
        <div
            style={{
                width: '1px',
                height: '24px',
                backgroundColor: '#e5e7eb',
                margin: '0 4px'
            }}
        />
    );

    const MenuBar = () => {
        if (!editor) return null;

        const commands = {
            bold: () => editor.chain().focus().toggleBold().run(),
            italic: () => editor.chain().focus().toggleItalic().run(),
            strike: () => editor.chain().focus().toggleStrike().run(),
            code: () => editor.chain().focus().toggleCode().run(),
            h1: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
            h2: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
            h3: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
            bulletList: () => editor.chain().focus().toggleBulletList().run(),
            orderedList: () => editor.chain().focus().toggleOrderedList().run(),
            codeBlock: () => editor.chain().focus().toggleCodeBlock().run(),
            blockquote: () => editor.chain().focus().toggleBlockquote().run(),
        };

        return (
            <div style={{
                borderBottom: '1px solid #e5e7eb',
                padding: '8px',
                display: 'flex',
                gap: '4px',
                flexWrap: 'wrap'
            }}>
                <MenuButton command={commands.bold} active={editor.isActive('bold')} >
                    <strong>B</strong>
                </MenuButton>

                <MenuButton command={commands.italic} active={editor.isActive('italic')} >
                    <em>I</em>
                </MenuButton>

                <MenuButton command={commands.strike} active={editor.isActive('strike')} >
                    <s>S</s>
                </MenuButton>

                <MenuButton command={commands.code} active={editor.isActive('code')} >
                    <code>{'</>'}</code>
                </MenuButton>

                <Divider />

                <MenuButton command={commands.h1} active={editor.isActive('heading', { level: 1 })} >
                    H1
                </MenuButton>

                <MenuButton command={commands.h2} active={editor.isActive('heading', { level: 2 })} >
                    H2
                </MenuButton>

                <MenuButton command={commands.h3} active={editor.isActive('heading', { level: 3 })} >
                    H3
                </MenuButton>

                <Divider />

                <MenuButton command={commands.bulletList} active={editor.isActive('bulletList')} >
                    •
                </MenuButton>

                <MenuButton command={commands.orderedList} active={editor.isActive('orderedList')} >
                    1.
                </MenuButton>

                <MenuButton command={commands.codeBlock} active={editor.isActive('codeBlock')} >
                    {'</>'}
                </MenuButton>

                <MenuButton command={commands.blockquote} active={editor.isActive('blockquote')} >
                    "
                </MenuButton>
            </div>
        );
    };

    return (
        <div>
            <div style={{
                border: '1px solid #e5e7eb',
                borderRadius: '8px',
                overflow: 'hidden',
                backgroundColor: 'white'
            }}>
                <MenuBar />
                <EditorContent editor={editor} />
            </div>
            Word count: {getWordCount(content)}
        </div>
    );
};

export default TipTapEditor;
